<template>
  <div class="page-container">
    <page-header></page-header>
    <acceptance-list :show-header="false" :order-page="getWorkIngPage">
      <template slot="table-title">
        <div>
          <div class="table-title">{{ $route.meta.title }}</div>
          <div class="segmentation-button-group">
            <div class="item " @click="$router.replace('/admin/service-order')">踏勘工单</div>
            <div class="item item-active" >施工工单</div>
          </div>
        </div>
      </template>
    </acceptance-list>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import AcceptanceList from "@/views/constructionAcceptance/List";
import {getWorkIngPage} from "@/api/apis";
export default {
  name: 'Construction',
  components: {
    AcceptanceList,
    PageHeader,
  },
  data(){
    return {
      getWorkIngPage
    }
  }
}
</script>

<style lang="scss" scoped>
.table-title{
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 20px;
  color: #18191A;
  font-weight: bold;
}
.segmentation-button-group{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 40px;
  background: #EEF1F5;
  border-radius: 4px;
  .item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 32px;
    border-radius: 4px;
    background-color: #ffffff00;

    font-size: 14px;
    color: #2F3133;

    cursor: pointer;
  }
  .item-active{
    background-color: #ffffff;
    color: #18191A;
  }
}
</style>
