<template>
  <div class="page-container">
    <page-header v-if="showHeader"></page-header>
    <page-table ref="table" show-paginate :get-data="orderPage" :fields="fields" :search-model="searchModel"
                show-serial-no :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="table-title">
        <slot name="table-title">
          <div class="table-title">{{ $route.meta.title }}</div>
        </slot>
      </template>
      <template slot="search-items">
        <el-form-item label="状态" prop="state">
          <el-select size="small" v-model="searchModel.state" clearable placeholder="施工状态">
            <el-option v-for="(label, value) in stateMap"
                       :key="value"
                       :label="label"
                       :value="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="电话号码" prop="webFarmerSearch">
          <el-input size="small" v-model="searchModel.webFarmerSearch" placeholder="电话号码" ></el-input>
        </el-form-item>
        <el-form-item  label="所在省" prop="webFarmerProvinceSearch">
          <el-input size="small" v-model="searchModel.webFarmerProvinceSearch" placeholder="所在省" ></el-input>
        </el-form-item>
        <el-form-item label="所在市区" prop="webFarmerCitySearch">
          <el-input size="small" v-model="searchModel.webFarmerCitySearch" placeholder="市区"  ></el-input>
        </el-form-item>
        <el-form-item  label="所在县/区" prop="webFarmerAreaSearch">
          <el-input size="small" v-model="searchModel.webFarmerAreaSearch" placeholder="县/区"></el-input>
        </el-form-item>
        <el-form-item  label="详细地址" prop="webFarmerAddressSearch">
          <el-input size="small" v-model="searchModel.webFarmerAddressSearch" clearable placeholder="详细地址"></el-input>
        </el-form-item>
        <el-form-item  label="施工人员" prop="webUserSearch">
          <el-input size="small" v-model="searchModel.webUserSearch" clearable placeholder="施工人员"></el-input>
        </el-form-item>
      </template>
    </page-table>
    <dispatch-order ref="dispatchOrder"
                    :working-salesman="workingSalesman"
                    @confirmDispatchOrder="confirmDispatchOrder"></dispatch-order>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageTable from "@/components/PageTable";
import {orderTransfer, salesmanPage} from "@/api/common";
import {constructionAcceptanceStatusMapper} from "@/utils/helper";
import {CONSTRUCTION_ACCEPTANCE_STATUS} from "@/utils/config";
import {getWorkIng, getWorkingSalesman, workIngExamine, workIngPd} from "@/api/apis";
import {examineMsg} from "@/pageInteraction/examine/ExamineMsg";
import DispatchOrder from "@/pageInteraction/DispatchOrder";
import StateShow from "@/components/StateShow.vue";

export default {
  name: "AcceptanceList",
  components: {DispatchOrder, PageTable, PageHeader},
  props: {
    showHeader: {
      type: Boolean,
      default(){
        return true
      }
    },
    orderPage: {
      type: Function,
      default: getWorkIng
    }
  },
  data() {
    return {
      searchModel: {
        // 状态
        state: '',
        // 省
        webFarmerProvinceSearch: '',
        // 市
        webFarmerCitySearch: '',
        // 区域
        webFarmerAreaSearch: '',
        // 地址
        webFarmerAddressSearch: '',
        // 电话号码
        webFarmerSearch: '',
        // 施工人员
        webUserSearch: '',
      },
      fields: [
        {
          label: '姓名',
          width: 110,
          render({farmer}, h) {
            return h('div', farmer ? farmer.name : '')
          }
        },
        {
          label: '电话号码',
          render({farmer}, h) {
            return h('div', farmer ? farmer.phone : '')
          }
        },
        {
          label: '所在地区',
          render: ({ farmer }, h) => {
            const { province, city, area } = farmer || {}
            return h('div', [province, city, area].join(''))
          }
        },
        {
          label: '详细地址',
          render: ({ farmer }, h) => {
            const { address } = farmer || {}
            return h('div', address)
          }
        },
        {
          label: '施工状态',
          width:100,
          render: ({ state }) => {
            return (<StateShow status={state} text={constructionAcceptanceStatusMapper(state)} />)
          }
        },
        {
          label: '所属公司',
          render({ createUser: user }, h) {
            const name = user?.distributor?.name || ''
            return h('div', name)
          }
        },
        // 施工人员
        {
          label: '施工人员',
          render({ user }, h) {
            const name = user?.name || ''
            return h('div', name)
          }
        },
        // 开工时间
        { label: '开工时间', key: 'startWorkDate' },
        // 结束时间
        { label: '结束时间', key: 'endWorkData' },
      ],
      actions: [
        { action: 'info', label: '查看', type: 'primary', permission: 'ys:see',
        },
        {
          action: 'dispatch',
          label: '派单',
          type: 'primary',
          showAction: item => {
            return item.state === 'INIT'
          }
        },
        {
          action: 'acceptance',
          label: '验收',
          type: 'primary',
          permission: 'ys:examine',
          showAction: item => {
            const info = this.$store.state.auth.user
            let flag = false

            if(item.state !== 'EXAMINE' && item.state !== 'EXAMINE_TWO'){
              return false
            }
            if(item.state === "EXAMINE"){
              //经销商 info.userType==="distributor"
              flag = info.userType === "distributor"
            }else {
              //开发商 info.userType==="enterprise"
              flag = info.userType === "enterprise"
            }
            return flag
          }
        },
      ],
      stateMap: CONSTRUCTION_ACCEPTANCE_STATUS,
      //
      transfer_form_loading: false,
      transferForm: {
        id: '',
        userId: '',
      },
      transferRules: {
        userId: [
          {required: true, message: '请选择业务员', trigger: 'blur'},
        ],
      },
      transfer_dialog_visible: false,
      searech_user_id_loading: false,
      user_options: [],
      info_dialog_visible: false,
      curInfo: null,

      //
      workingSalesman: [],
    }
  },
  created() {

  },
  mounted() {
    getWorkingSalesman().then(res => {
      this.workingSalesman = res.records
    })
  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    // action---------------------
    /**
     * 查看详情
     * @param item
     */
    infoAction(item) {
      this.$router.push('/admin/acceptance/info/' + item.id)
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    dispatchAction(item){
      this.$nextTick(() => {
        this.$refs.dispatchOrder.open()
        this.currItem = item
      })
    },
    acceptanceAction(item){
      examineMsg(info => {
        const params = {
          workIngId: item.id,
          examineMsg: info.msg,
          state: info.state,
        }

        const loading = this.$loading({ fullscreen: true })
        workIngExamine(params).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$refs.table && this.$refs.table.loadData()
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    // --------------------------
    confirmDispatchOrder(dispatchOrderUser){
      const params = {
        userId: dispatchOrderUser,
        workIngId: this.currItem.id,
      }

      const loading = this.$loading({ fullscreen: true })
      workIngPd(params).then(() => {
        this.$message.success('提交成功')
        setTimeout(() => {
          this.$refs.table && this.$refs.table.loadData()
        }, 500)
      }).finally(() => {
        loading.close()
      })
    },
    userIdRemoteMethod(query) {
      if (query == '') return
      let that = this
      that.searech_user_id_loading = true
      salesmanPage({developerName: query}).then(res => {
        that.user_options = res.records
        that.searech_user_id_loading = false
      }).catch(() => {
        that.user_options = []
        that.searech_user_id_loading = false
      })
    },
    transferSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.transfer_form_loading = true
        orderTransfer(that.transferForm.id, that.transferForm.userId).then(() => {
          that.transfer_form_loading = false
          that.$message.success('提交成功')
          that.transfer_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.transfer_form_loading = false
        })
      })
    },
  },
}
</script>

<style scoped>

</style>